
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonModal,
  IonContent,
  IonIcon,
  IonSlides,
  IonSlide,
  IonImg
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'

class Props {
  src!: string
}

@Component({
  components: {
    PageHeader,
    PageLayout,
    IonRow,
    IonCol,
    IonModal,
    IonContent,
    IonIcon,
    IonSlides,
    IonSlide,
    IonImg
  },
  props: {
    src: {
      default: ['https://th-test-11.slatic.net/p/5568633b84a71986d67de8c5f290ddae.jpg'],
      type: Array
    }
  }
})
export default class ImagesDialog extends Vue.with(Props) {
  modal = {
    isActive: false,
    startIndex: -1
  }

  slideChanged(v: any) {
    console.log(v)
  }
}
