<template>
  <div></div>
</template>
<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import store from '@/store'
class Props {
  token!: string
  freshChatIntegrationId!: string
  userRestoreId?: string
}

declare const window: any

@Component({
  components: {},
  props: ['token', 'freshChatIntegrationId', 'userRestoreId']
})
export default class FreshChat extends Vue.with(Props) {
  user = store.state.user
  freshChatDisplay = false
  displayRoutes = ['/home']
  elementId = 'freshchat-js-sdk'

  get getRoute() {
    return this.$route.fullPath
  }

  mounted() {
    this.$watch('getRoute', () => {
      this.checkRoute()
    })
    if (window.location.pathname === '/home') {
      try {
        this.initFreshchat()
      } catch (e) {
        console.log('initFreshChat failed', e)
      }
    }
    this.checkRoute()
  }

  checkRoute() {
    const freshChatContainer = document.getElementById('fc_frame')
    if (freshChatContainer && this.displayRoutes.includes(this.$route.fullPath)) {
      freshChatContainer.style.display = 'block'
    } else if (freshChatContainer) {
      freshChatContainer.style.display = 'none'
    }
  }

  initFreshchat() {
    document.getElementById(this.elementId)
      ? this.launchFreshChat()
      : this.loadFreshChat()
    this.freshChatDisplay = true
  }

  loadFreshChat() {
    let e = null as any
    ;((e = document.createElement('script')).id = this.elementId),
      (e.async = !0),
      (e.src = 'https://wchat.freshchat.com/js/widget.js'),
      (e.onload = this.launchFreshChat)
    document.head.appendChild(e)
  }

  launchFreshChat() {
    window.fcWidget.init({
      token: this.token,
      host: 'https://wchat.freshchat.com',
      externalId: this.user.id,
      restoreId: this.userRestoreId,
      config: {
        cssNames: {
          widget: 'fc_frame'
        }
      }
    })
    this.bindFreshChatUser()
    this.updateFreshChatProfile()
  }

  bindFreshChatUser() {
    window.fcWidget.on('user:created', (response: any) => {
      const status = response?.status
      const data = response?.data
      const profileSaved = status === 200 && data?.restoreId
      const restoreId = data?.restoreId
      if (profileSaved && restoreId && this.userRestoreId !== restoreId) {
        this.$http.put(`/fresh_chat_integration/${this.freshChatIntegrationId}`, {
          restoreId: restoreId
        })
      }
    })
  }

  updateFreshChatProfile() {
    window.fcWidget.user.setProperties({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      phone: this.user.phoneNumber
    })
  }
}
</script>

<style lang="scss">
//@supports (-webkit-touch-callout: none) {
.fc_frame {
  margin-bottom: 50px !important;
  &.fc-open {
    padding-top: 40px !important;
    margin-bottom: 0 !important;
  }
}
//}
</style>
