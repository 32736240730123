
import { Options as Component, Vue } from 'vue-class-component'
import { IonCard, IonCardContent, IonCol, IonRow, IonButton, IonGrid } from '@ionic/vue'
import SmallRedDot from '@/components/badge/SmallRedDot.vue'
import { Card } from '@/components/Card'
import BillState from '@/modules/bills/BillState.vue'
import _ from 'lodash'

class Props {
  invoice!: any
  cardStyle?: string
  disabled?: boolean
  redirectDisabled?: boolean
}

@Component({
  components: {
    IonCard,
    IonCardContent,
    IonCol,
    IonRow,
    SmallRedDot,
    BillState,
    IonButton,
    IonGrid,
    Card
  },
  props: ['invoice', 'cardStyle', 'disabled', 'redirectDisabled']
})
export default class BillCard extends Vue.with(Props) {}
