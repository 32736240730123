<template>
  <PageLayout :background="'color'">
    <div id="recaptcha-container" />

    <IonGrid class="main-layout">
            <IonIcon
              class="back-icon cursor-pointer"
              src="/assets/icon/chevron-back.svg"
              @click="$router.push('/login')"
              style='min-width:45px; '

            />
<!--      <IonButton  @click="$router.push('/login')" fill='clear' icon-only  >-->
<!--        <ion-icon slot="start" name="star"></ion-icon>-->
<!--        <img src='/assets/icon/chevron-back.svg' alt=''>-->
<!--      <IonIcon-->
<!--        class="back-icon"-->
<!--        src="/assets/icon/chevron-back.svg"-->
<!--        slot="start"-->
<!--      />-->
<!--      </IonButton >-->

      <IonRow class="ion-justify-content-center" style="margin-bottom: 23px">
        <IonCol size="auto">
          <img alt="logo" src="/assets/sky_logo.svg" width="170" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol class="otp-text pb-3">
          {{ $t('login.verify_phone_number_6_digits') }}</IonCol
        >
      </IonRow>

      <IonRow class="ion-justify-content-center pb-3">
        <IonCol class="otp-input pa-0" size="auto">
          <div class="otp-underline">
            <IonInput
              class="ion-no-padding ion-no-margin"
              v-model="otp"
              maxlength="6"
              name="otp"
              pattern="\d*"
              placeholder="000000"
              type="text"
            />
          </div>
        </IonCol>
      </IonRow>

      <IonRow
        class="ion-justify-content-center fill-height otp-text pt-2"
        style="margin-bottom: 17px"
      >
        <IonCol size="auto" v-if="!(counter.min === 0 && counter.sec === 0)">
          <div class="timer-background">{{ counter.display }}</div>
        </IonCol>
        <IonCol class="ion-justify-content-center" size="auto">
          <div class="vertical-middle resent-code-text">
            {{ $t('login.label.didnt_get_code') }}
          </div>
        </IonCol>
        <IonCol size="auto">
          <div
            class="vertical-middle resent-code-text"
            :style="
              `color: ${counter.min === 0 && counter.sec === 0 ? '#01b3bf' : '#c6c6c6'}`
            "
            @click="
              () => {
                counter.min === 0 && counter.sec === 0 ? resendOtp() : null
              }
            "
          >
            <b>
              <u>
                {{ $t('login.label.resend_code') }}
              </u>
            </b>
          </div>
        </IonCol>
      </IonRow>

      <IonRow class="ion-justify-content-center pt-3">
        <IonCol size="auto">
          <IonButton
            :disabled="otp?.length !== 6"
            class="btn-gradient verify-button"
            @click="verify()"
          >
            {{ $t('login.label.verify') }}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
    <VerifyOtpErrorModal
      v-if="errorModalOpen"
      :errorCode="errorCode"
      v-on:close="errorModalOpen = false"
    />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonPage,
  IonRow,
  isPlatform
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import VerifyOtpErrorModal from '@/modules/authentication/VerifyOtpErrorModal.vue'
import firebase from 'firebase/app'
import { getLogInDevice } from '@/utils/deviceUuid'
import { FirebaseX } from '@ionic-native/firebase-x'

@Component({
  components: {
    PageLayout,
    IonPage,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    VerifyOtpErrorModal
  }
})
export default class VerifyOtp extends Vue {
  otp = (null as unknown) as number
  counter = { min: 3, sec: 0, display: '3:00' } as any
  errorModalOpen = false
  errorCode = ''

  mounted() {
    this.startTimer()
  }

  startTimer() {
    setInterval(() => {
      if (!this.counter.sec && !!this.counter.min) {
        this.counter.min--
        this.counter.sec = 60
      }

      if (this.counter.sec) {
        this.counter.sec--
      }
      this.counter.display =
        (this.counter.min < 10 ? '0' + this.counter.min : this.counter.min) +
        ':' +
        (this.counter.sec < 10 ? '0' + this.counter.sec : this.counter.sec)
    }, 1000)
  }

  verify() {
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      this.$route.query.verificationId as string,
      this.otp.toString()
    )

    firebase
      .auth()
      .signInWithCredential(phoneCredential)
      .then(async userCredential => {
        if (userCredential.additionalUserInfo?.isNewUser) {
          const data = {
            externalId: userCredential.user?.uid,
            phoneNumber: userCredential.user?.phoneNumber
          }
          console.log(data, 'data')
          await this.createUserWithBasicAuth(data)
        }
      })
      .then(() => this.acceptPolicyAndTerms())
      .catch(error => {
        console.log('error ', error)
        this.errorModalOpen = true
        this.errorCode = error?.code
      })
  }

  acceptPolicyAndTerms() {
    const { uuid, source } = getLogInDevice()
    const params = { deviceId: uuid, deviceSource: source }

    this.$http
      .post('/policy/latest/accept', params)
      .then(() => this.$router.push('/home'))
      .catch(console.log)
  }
  //TODO: @din please fix this
  async createUserWithBasicAuth(data: any) {
    const username = process.env.VUE_APP_BASIC_AUTH_USERNAME
    const password = process.env.VUE_APP_BASIC_AUTH_PASSWORD
  }

  async resendOtp() {
    await new Promise(resolve => {
      if (isPlatform('ios')  && isPlatform('cordova')) {
        const response = FirebaseX.verifyPhoneNumber(
          '+66' + this.$route.query.phoneNumber,
          300000
        )
        resolve(response)
      } else {
        const response = firebase.auth().signInWithPhoneNumber(
          // const response = await FirebaseAuthentication.verifyPhoneNumber(
          '+66' + this.$route.query.phoneNumber,

          new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible'
          })
        )
        resolve(response)
      }
    }).then((response: any) => {
      this.$router.push({
        path: '/verify_otp',
        query: {
          verificationId: response.verificationId,
          phoneNumber: this.$route.query.phoneNumber
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.back-icon {
  font-size: 30px;
  position: absolute;
  top: 50px;
  color: #333;
}

.main-layout {
  padding: 96px 15px 0 15px;
}

.otp-text {
  font: normal normal normal 14px Prompt;
  color: #333333;
  text-align: center;

  ion-col {
    padding-left: 3px;
    padding-right: 3px;
  }
}

.timer-background {
  color: white;
  text-align: center;
  background: #01b3bf;
  border-radius: 4px;
  padding: 5px;
  width: 50px;
  font-size: 14px;
}

.verify-button {
  width: 200px;
  height: 43px;
  font-size: 16px;
}

.otp-input {
  padding: 0 !important;
  margin: 0 !important;

  ion-input {
    width: 140px;
    font-size: 25px;
  }

  .otp-underline {
    border-bottom: 1px solid #c6c6c6;
    //opacity: 0.4;
    letter-spacing: 8px;
    //width: 97px;
    //height: 1px;
    //position: absolute;
    //margin-top: -15px;
    //margin-left: calc(0% + 0.4vw);
  }
}

.vertical-middle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
}

.resent-code-text {
  font-size: 14px;
}
</style>
