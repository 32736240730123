<template>
  <PageLayout :background="'color'">
    <IonRow
      class="ion-align-items-center ion-align-self-center fill-height"
      style="height: 80vh"
    >
      <IonCol size="12">
        <IonRow class="ion-justify-content-center">
          <IonCol size="auto">
            <img src="/assets/sky_logo.svg" width="170" alt="logo" />
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center pt-2">
          <IonCol size="auto">
            <IonButton class="btn btn-gradient" @click="startedBtnClicked()">
              {{ $t('login.label.get_started') }}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import {
  IonPage,
  IonContent,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonButton
} from '@ionic/vue'

class Props {}

@Component({
  components: {
    PageLayout,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonItem,
    IonButton
  },
  props: []
})
export default class LandingPage extends Vue.with(Props) {
  startedBtnClicked() {
    try {
      console.log('STARTD: get start')
      this.$router.push('/login')
    } catch (e) {
      console.log('STARTD: get start error', e)
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 200px;
  height: 43px;
  font-size: 16px;
}
</style>
