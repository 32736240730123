<template>
  <Card
    :cardStyle="cardStyle"
    :disabled="disabled"
    :class="{ 'bill-card': true, disabled: disabled }"
    @click="() => !disabled && !redirectDisabled && $router.push(`/bills/${invoice._id}`)"
  >
    <template v-slot:disabled-message>
      <slot name="disabled-message" />
    </template>

    <IonGrid class="bill-content">
      <IonRow class="ion-justify-content-between">
        <IonCol size="7">
          <div style="display: flex">
            <SmallRedDot :hidden="invoice.viewed" />
            <span class="bill-number">{{ invoice.number }}</span>
          </div>
          <div class="bill-details ellipsis">
            <span>{{ `${$t('bill.label.due_date')} : ` }}</span>
            <span>{{ THAI_DATE(invoice.dueAt) }}</span>
          </div>
          <div class="bill-details ellipsis">
            <span>{{ `${$t('bill.label.issue_date')} : ` }} </span>
            <span>{{ THAI_DATE(invoice.issuedAt) }} </span>
          </div>
          <div class="bill-details ellipsis">
            <span>{{ `${$t('bill.label.project')} : ` }} </span>
            <span
              >{{
                I18N(invoice?.invoiceProject?.nameTh, invoice?.invoiceProject?.nameEn)
              }}
            </span>
          </div>
          <div class="bill-details ellipsis">
            <span>{{ `${$t('bill.label.unit')} : ` }} </span>
            <span>
              {{ invoice.invoiceUnits?.map(unit => unit.unitName).join(' / ') }}
            </span>
          </div>
          <div
            v-if="invoice.totalCreditNoteCents !== 0"
            class="bill-details credit-note ellipsis"
          >
            <span>{{ `${$t('bill.label.credit_note')} : ` }} </span>
            <span>
              {{ invoice.totalCreditNote?.fullDisplay }}
            </span>
          </div>
        </IonCol>

        <IonCol size="5">
          <div style="position: absolute; top: 0; right: 0; margin-right: -4px">
            <BillState :invoice="invoice" />
          </div>
          <div style="bottom: 0; right: 0; position: absolute">
            <div style="text-align: right; color: #333333; font-size: 16px">
              <b>
                {{ invoice?.grandTotalAfterCreditNote?.fullDisplay }}
              </b>
            </div>
            <IonButton
              v-if="invoice?.state === 'paid' || invoice?.state === 'processing'"
              class="btn-gradient view-details-button "
            >
              {{ $t('button.view_details') }}
            </IonButton>
            <IonButton v-else class="btn-gradient pay-now-button" expand="block">
              {{ $t('bill.label.pay_now') }}
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  </Card>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonCard, IonCardContent, IonCol, IonRow, IonButton, IonGrid } from '@ionic/vue'
import SmallRedDot from '@/components/badge/SmallRedDot.vue'
import { Card } from '@/components/Card'
import BillState from '@/modules/bills/BillState.vue'
import _ from 'lodash'

class Props {
  invoice!: any
  cardStyle?: string
  disabled?: boolean
  redirectDisabled?: boolean
}

@Component({
  components: {
    IonCard,
    IonCardContent,
    IonCol,
    IonRow,
    SmallRedDot,
    BillState,
    IonButton,
    IonGrid,
    Card
  },
  props: ['invoice', 'cardStyle', 'disabled', 'redirectDisabled']
})
export default class BillCard extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
ion-card.bill-card {
  width: 100%;

  .bill-content {
    padding: 5px 10px 5px 10px;
  }

  .bill-number {
    color: $color-primary-dark;
    font-size: 18px;
  }

  .bill-state-wrapper {
    position: absolute;
    top: 4px;
    right: 0%;
    margin-right: -4px;
  }

  .bill-pay-wrapper {
    bottom: 0%;
    right: 0%;
    position: absolute;

    .bill-grand-total {
      text-align: right;
      color: #333333;
      font-size: 14px;
    }
  }

  .bill-details {
    font-size: 14px;
    margin-bottom: 2px;
    display: block;
    white-space: nowrap;

    span:first-child {
      color: #9a9a9a;
    }
    span:last-child {
      color: #333333;
    }
  }

  .credit-note {
    span:first-child {
      color: $color-primary;
    }
    span:last-child {
      color: $color-primary;
    }
  }

  .pay-now-button {
    --border-radius: 5px;
    text-transform: uppercase;
    font-size: 16px;
    width: 96px;
    height: 30px;
    float: right;
    transform: translateX(2px);
  }
}

ion-card.bill-card.disabled {
  .bill-content {
    opacity: 0.2;
  }

  .bill-number {
    color: #555;
  }

  .pay-now-button {
    --background: #888;
    --color: #555;
    opacity: 1;
  }

  .bill-details {
    span {
      color: #555;
    }
  }
}

.view-details-button {
  --border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  height: 30px;
  float: right;
  --padding-top: 2px;
  margin-bottom: 0px;
}
</style>
