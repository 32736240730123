<template>
  <div class="bill-state-container">
    <div :class="`pill bill-state--${disabled ? 'disabled' : state}`">
      <div class="bill-state-text invisible"> {{ $t(`bill.status.${state}`) }} </div>
    </div>
    <div class="bill-state-text fix-padding">
      {{ $t(`bill.status.${state}`) }}
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  invoice!: any
  disabled?: boolean
}

@Component({
  components: {},
  props: ['invoice', 'disabled']
})
export default class BillState extends Vue.with(Props) {
  get state() {
    return this.computeBillState(this.invoice)
  }

  computeBillState(invoice: any) {
    if (invoice?.isOverdued) {
      return 'overdue'
    }
    return invoice?.state
  }
}
</script>

<style scoped lang="scss">
.bill-state-container {
  float: right;
  text-align: center;
}
.pill {
  z-index: 1000;
  background: black;
  border-radius: 5px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  text-align: center;
  padding: 0px 7px 0px 7px;
  height: 17px;
}
.bill-state-text {
  text-transform: capitalize;
  text-align: center;
  font: normal normal normal 16px/21px Prompt;
  letter-spacing: 0px;
  color: #ffffff;
}
.invisible {
  opacity: 0;
}
.fix-padding {
  margin-top: -18px;
}
@mixin state-class($color) {
  color: white;
  border-radius: 5px;
  background-color: $color;
}
.bill-state {
  &--disabled {
    @include state-class(#888);
    div {
      color: #000;
    }
  }
  &--cancelled {
    @include state-class(orange);
  }
  &--paid {
    @include state-class(#499f6b);
  }
  &--pending {
    @include state-class(#f5c935);
  }
  &--processing {
    @include state-class(#01b3bf);
  }
  &--overdue {
    @include state-class(#ab4465);
  }
  &--voided {
    @include state-class(#717171);
  }
}
</style>
