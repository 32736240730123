
import { Options as Component, Vue } from 'vue-class-component'
import { IonRow, IonCol, IonButton } from '@ionic/vue'
import FloatModal from '@/components/modals/FloatModal.vue'
import { Market } from '@ionic-native/market'
import { AppVersion } from '@ionic-native/app-version'
import { isPlatform } from '@ionic/vue'
import { parseInt } from 'lodash'
class Props {}

@Component({
  components: { FloatModal, IonRow, IonCol, IonButton },
  props: {}
})
export default class ForceUpdateModal extends Vue.with(Props) {
  updateNeeded = false
  async mounted() {
    if (isPlatform('cordova'))
      await this.$http.get('/app/version').then(async res => {
        const currentVersionCode = (await AppVersion.getVersionCode()).toString()
        console.log('currentVersionCode', currentVersionCode)
        if (
          (isPlatform('ios') &&
            parseInt(res.data.iosCode) > parseInt(currentVersionCode)) ||
          (isPlatform('android') &&
            parseInt(res.data.androidCode) > parseInt(currentVersionCode))
        ) {
          this.updateNeeded = true
        }
      })
  }
  async updateClicked() {
    const packageName = isPlatform('ios') ? '1576467469' : 'sky.commercial.app'
    await Market.open(packageName)
  }
}
