<template>
  <IonCard
    v-if="cardStyle === 'outline'"
    :button="button"
    :class="['card', 'outer-wrapper', 'outline', cssClass, { disabled: disabled }]"
    :style="cssStyle"
  >
    <div v-if="disabled" class="transparent-mask">
      <div class="transparent-mask-inner">
        <div>
          <slot name="disabled-message" />
        </div>
      </div>
    </div>
    <slot />
  </IonCard>
  <IonCard v-else :button="button" class="outer-wrapper">
    <IonCard class="wrapper">
      <IonCard :class="'card ' + cssClass" :style="cssStyle">
        <slot />
      </IonCard>
    </IonCard>
  </IonCard>
</template>
<script lang="ts">
import { IonCard } from '@ionic/vue'
import { Options as Component, Vue } from 'vue-class-component'

class Props {
  cssClass?: string
  cssStyle?: string
  button?: boolean
  cardStyle?: string | 'outline'
  disabled?: boolean
}

@Component({
  components: { IonCard },
  props: {
    cssClass: {
      type: String
    },
    cssStyle: {
      type: String,
      default: ''
    },
    button: {
      type: Boolean,
      default: true
    },
    cardStyle: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class Card extends Vue.with(Props) {}
</script>

<style scoped lang="scss">
ion-card.card {
  box-shadow: none !important;
  margin: 0px 0px;
  margin-bottom: 3px;
}
ion-card.outer-wrapper {
  --background: white;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 8px !important;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  margin: 6px 0px 6px 0px;
}
ion-card.wrapper {
  --background: linear-gradient(to right, #04b3bf, #038caa);
  padding: 0px 0px 0px 0px;
  margin: 0px 0px;
  box-shadow: none !important;
}
ion-card.outline {
  border: 0.5px solid $color-primary;
}

ion-card.outline.disabled {
  border: 0.5px solid #bdbdbd;
}

.transparent-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: black;
  background: #bdbdbd;

  .transparent-mask-inner {
    height: 100%;
    width: 100%;
    display: table;

    div {
      vertical-align: middle;
      height: 100%;
      display: table-cell;
      font-size: 16px;
      color: #333333;
    }
  }
}
</style>
