
import { Options as Component, Vue, setup } from 'vue-class-component'
import { IonRow, IonCol, IonButton, IonImg } from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import ProjectCard from '@/components/ProjectCard.vue'
import QuickActionBlock from '@/components/QuickActionBlock.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import PushNotification from '@/components/PushNotification/PushNotification.vue'
import { reactive } from 'vue'
import AnnouncementSlide from '@/components/Announcement/AnnouncementSlide.vue'
import Profile from '@/components/ProfileBanner.vue'
import BottomNavigationMenu from '@/views/BottomNavigationMenu.vue'
import _ from 'lodash'
const useHomeMenu = () => {
  const countUnreadNotifications = reactive({
    postCount: 0,
    newsCount: 0,
    invoiceCount: 0
  })
  const menuList = reactive([
    {
      src: '/assets/icon/bill_homeicon.png',
      redDot: {
        count: 0
      },
      name: 'bill',
      routerLink: 'bills',
      rules: ['bill_read', 'bill_process']
    },
    {
      src: '/assets/icon/news_homeicon.png',
      redDot: {
        count: 0
      },
      name: 'news',
      routerLink: '/news'
    },
    // {
    //   src: '/assets/icon/post_homeicons.png',
    //   redDot: {
    //     count: 0
    //   },
    //   name: 'post',
    //   routerLink: '/posts'
    // },
    // {
    //   src: '/assets/icon/visitors_homeicons.png',
    //   redDot: {
    //     count: 0
    //   },
    //   name: 'visitor',
    //   routerLink: '/visitors'
    // },
    {
      src: '/assets/icon/maintenance_homeicon.png',
      redDot: {
        count: 0
      },
      name: 'maintenance',
      routerLink: '/maintenance'
    },
    {
      src: '/assets/icon/services_homeicon.png',
      redDot: {
        hidden: true
      },
      name: 'service',
      routerLink: '/services'
    }
    // {
    //   src: '/assets/icon/space_booking_homeicon.png',
    //   redDot: {
    //     count: 0
    //   },
    //   name: 'space_booking',
    //   routerLink: '/home'
    // }
  ])
  return { menuList, countUnreadNotifications }
}
class Props {
  $refs!: any
}
declare const window: any
@Component({
  components: {
    BottomNavigationMenu,
    Profile,
    HomeMenu,
    QuickActionBlock,
    ProjectCard,
    PageLayout,
    IonRow,
    IonCol,
    PushNotification,
    AnnouncementSlide,
    IonButton,
    IonImg
  }
})
export default class Home extends Vue.with(Props) {
  // freshChatData = {
  //   _id: '' as any,
  //   isEnabled: false,
  //   freshChatToken: '' as any,
  //   userTokens: [] as { restoreId: string }[],
  //   userRestoreId: '' as any
  // } as any
  displayRoutes = ['/home']
  announcements = []
  user = {
    profileImage: '',
    firstName: ''
  }
  invoices = []
  countUnreadMenuNotification = {
    postCount: 0,
    newsCount: 0,
    invoiceCount: 0
  }
  invoiceGroups: any = []

  menuData = setup(() => {
    return useHomeMenu()
  })
  get freshChat() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    return {
      _id,
      isEnabled,
      freshChatToken,
      userTokens
    }
  }

  get userRestoreId() {
    return this.freshChat?.userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
  }
  get freshChatData() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    const userRestoreId = userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
    return { _id, isEnabled, freshChatToken, userTokens, userRestoreId }
  }
  async mounted() {
    await this.getData()
  }

  async getData() {
    return new Promise(resolve => {
      this.$http
        .all([
          this.$http
            .get('/invoice', {
              params: {
                isGrouped: 0,
                state: ['pending', 'overdue'],
                page: 1,
                perPage: 2,
                orderBy: 'asc',
                orderField: 'dueAt'
              }
            })
            .catch(console.log),
          this.$http
            .get('/invoice/group', {
              params: {
                state: ['pending', 'overdue'],
                page: 1,
                perPage: 2,
                orderBy: 'asc',
                orderField: 'dueAt'
              }
            })
            .catch(console.log),
          this.$http
            .get('/announcement', { params: { perPage: 5, highlighted: 1 } })
            .catch(console.log),
          this.$http.get('/capi/project').catch(console.log)
        ])
        .then(resArr => {
          //  set user
          this.user = this.$store.state.user

          //  set invoices
          if (resArr[0]) this.invoices = resArr[0].data?.data

          //  set invoice groups
          if (resArr[1]) this.invoiceGroups = resArr[1].data?.data

          //set announcement array
          if (resArr[2]) this.announcements = resArr[2].data

          //menu notification
          if (resArr[0]) {
            const { unreadInvoiceCount } = resArr[0].data
            this.menuData.menuList[0].redDot.count = unreadInvoiceCount
          }
          const { newsViewCount, postViewCount } = this.$store.state.user
          this.menuData.menuList[1].redDot.count = newsViewCount
          this.menuData.menuList[2].redDot.count = postViewCount

          // set current project & company
          if (resArr[3]) {
            const projects = resArr[3].data
            if (this.$store.state.currentProject?.id < 0) {
              this.$store.state.currentProject = projects[0]
              this.$store.state.currentCompany.id = projects[0]?.companyId
            }
          }
          resolve(true)
        })
    })
  }

  checkDisableHomeMenu(navigationPermissions: any, rules: any) {
    return !_.intersection(navigationPermissions, rules).length
  }

  setShowSelectProjectModal() {
    console.log('setShowSelectProjectModal')
  }

  initFreshDesk() {
    window.fwSettings = {
      widget_id: 70000000510
    }
    const freshdesk = () => {
      if ('function' != typeof window.FreshworksWidget) {
        const n: any = function() {
          // eslint-disable-next-line prefer-rest-params
          n.q.push(arguments)
        }
        ;(n.q = []), (window.FreshworksWidget = n)
      }
    }
  }
}
