<template>
  <div>
    <div>
      <div class="quick-action-title">{{ $t('home.quick_action') }}</div>
      <EmptyListPlaceHolder :content="$t('home.no_quick_action')" v-if="!invoices" />

      <div v-else>
        <div class="margin-auto">
          <!--          <div class="quick-action-title">{{ $t('home.quick_action') }}</div>-->
          <div v-for="(invoice, i) in invoices" :key="i">
            <BillCard v-if="invoice.entity === 'invoice'" :invoice="invoice" />
            <BillGroupCard
              v-if="invoice.entity === 'invoiceGroup'"
              :minimized="true"
              :invoiceGroup="invoice"
            />
          </div>
          <!-- <IonRow class="ion-justify-content-end">
            <IonCol size="auto">
              <a
                style="font-size: 12px"
                class="router-link"
                @click="$router.push('/bills')"
                v-if="invoices.length > 0"
              >
                {{ $t('home.see_more_invoices') }} >
              </a>
            </IonCol>
          </IonRow> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import EmptyListPlaceHolder from '@/components/EmptyListPlaceHolder.vue'
import SeeDetails from '@/components/SeeDetails.vue'
import { BillCard } from '@/modules/bills'
import BillGroupCard from '@/modules/bills/BillGroupCard.vue'
import { IonRow, IonCol } from '@ionic/vue'

class Props {
  invoices!: []
}
@Component({
  components: {
    BillCard,
    BillGroupCard,
    SeeDetails,
    EmptyListPlaceHolder,
    IonRow,
    IonCol
  },
  props: ['invoices']
})
export default class QuickActionBlock extends Vue.with(Props) {}
</script>

<style lang="scss">
.router-link {
  color: #92949c;
  text-decoration: none;
  cursor: pointer;
}

.quick-action-title {
  height: 30px;
  text-align: left;
  font: normal normal normal 20px/30px Prompt;
  color: #333333;
  text-transform: capitalize;
  margin-left: 7.5px;
}
</style>
