<template>
  <IonApp :style="`height: ${isIos() ? 'calc(100vh - 20px);' : '100%'}`">
    <!--<IonApp>-->
    <IonRouterOutlet :key="$route.fullPath" />
    <Loading
      :active="this.$store?.state.loadingActive"
      :can-cancel="false"
      :color="'#01d3bf'"
      :is-full-page="true"
    />

    <AppModal />
    <DialogMaintenance />
    <ForceUpdateModal />
  </IonApp>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonApp, IonRouterOutlet, IonFooter } from '@ionic/vue'
import AppModal from '@/components/modals/AppModal.vue'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import FreshChat from '@/components/FreshChat.vue'
import { isPlatform } from '@ionic/vue'
import DialogMaintenance from '@/components/DialogMaintenance.vue'
import FloatModal from '@/components/modals/FloatModal.vue'
import ForceUpdateModal from '@/components/modals/ForceUpdateModal.vue'
@Component({
  components: {
    ForceUpdateModal,
    FloatModal,
    DialogMaintenance,
    IonApp,
    IonRouterOutlet,
    Loading,
    IonFooter,
    AppModal,
    FreshChat
  }
})
export default class App extends Vue {
  get freshChatData() {
    const { _id, isEnabled, freshChatToken, userTokens } =
      this.$store.state.currentProject?.freshChatIntegration || {}
    const userRestoreId = userTokens?.find(
      userToken => userToken?.userId == this.$store.state.user.id
    )?.restoreId
    return { _id, isEnabled, freshChatToken, userTokens, userRestoreId }
  }
  mounted() {
    // document.body.classList.toggle('dark', false)
    // document.body.classList.toggle('light', true)

    // StatusBar.overlaysWebView(true)
    // StatusBar.backgroundColorByHexString('#ffffff')
    // StatusBar.styleLightContent()
    const language = localStorage.getItem('language')
    if (language === 'th' || language === 'en') this.$i18n.locale = language
  }
  isIos() {
    return isPlatform('ios')
  }
}
</script>

<style scoped></style>
