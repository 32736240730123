<template>
  <div>
    <div
      @click="
        () => {
          modal.startIndex = i
          modal.isActive = true
        }
      "
      :key="i"
      v-for="(image, i) in src"
      :style="'background-image: url(' + GET_SECURED_IMAGE(image) + ');'"
      class="card-img mb-3"
    />

    <IonModal :isOpen="modal.isActive" backdropDismiss="false">
      <IonContent>
        <IonRow class="pa-3 fill-height">
          <IonCol>
            <IonRow class="ion-justify-content-between ion-align-items-center text mb-5">
              <IonCol size="1" />

              <IonCol size="auto" class="f-18">News</IonCol>
              <IonCol size="1">
                <IonIcon
                  size="large"
                  src="/assets/icon/close-round-white.svg"
                  @click="modal.isActive = false"
                />
              </IonCol>
            </IonRow>

            <IonRow class="ion-justify-content-center mb-3">
              <IonCol size="auto" class="f-14">
                {{ modal.startIndex + 1 }} of {{ src.length }}
              </IonCol>
            </IonRow>
            <IonSlides
              pager="false"
              :options="{
                initialSlide: modal.startIndex,
                speed: 400
              }"
              :onIonSlideNextEnd="
                () => {
                  modal.startIndex += 1
                }
              "
              :onIonSlidePrevEnd="
                () => {
                  modal.startIndex -= 1
                }
              "
            >
              <IonSlide v-for="(image, i) in src" :key="i">
                <img :src="GET_SECURED_IMAGE(image)" alt="image" width="100%" />
              </IonSlide>
            </IonSlides>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonModal>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonRow,
  IonCol,
  IonModal,
  IonContent,
  IonIcon,
  IonSlides,
  IonSlide,
  IonImg
} from '@ionic/vue'
import PageLayout from '@/components/Layout/PageLayout.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'

class Props {
  src!: string
}

@Component({
  components: {
    PageHeader,
    PageLayout,
    IonRow,
    IonCol,
    IonModal,
    IonContent,
    IonIcon,
    IonSlides,
    IonSlide,
    IonImg
  },
  props: {
    src: {
      default: ['https://th-test-11.slatic.net/p/5568633b84a71986d67de8c5f290ddae.jpg'],
      type: Array
    }
  }
})
export default class ImagesDialog extends Vue.with(Props) {
  modal = {
    isActive: false,
    startIndex: -1
  }

  slideChanged(v: any) {
    console.log(v)
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: #fff;
}

.card-img {
  border-radius: 10px;
  width: 100%;
  height: 180px;
  background-position: center center;
  background-repeat: no-repeat;
}

ion-content {
  --background: #333333 !important;
}
</style>
