<template>
  <PageLayout>
    <PageHeader :title="$t('maintenance.title')" :withBackButton="true">
      <template v-slot:action>
        <IonButton mode="md" fill="clear" :style="{ float: 'right' }">
          <IonIcon color="primary" icon="duplicate" />
        </IonButton>
      </template>
    </PageHeader>
    <IonRow>
      <IonCol size="12">
          <h4 class="pt-3">
          <center>
          รับเฉพาะเเจ้งซ่อม<br/>กรุณาติดต่อตามเบอร์<br/>หรือ อีเมล์ที่เเจ้งไว้เเต่ละศูนย์
        </center>
        </h4>
      </IonCol>
    </IonRow>
   <div  style="padding-left: 40px;
    padding-top: 16px; padding-bottom: 50px;">
    <IonRow>
      <IonCol size="12">
        Crystal Design Center
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:jintana.m@kegroup.co.th">jintana.m@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:095-159-5709">095-159-5709</a>
      </IonCol>
    </IonRow>
    <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        The Crystal
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:jintana.m@kegroup.co.th">jintana.m@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:095-159-5709">095-159-5709</a>
      </IonCol>
    </IonRow>
      </div>
     <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        The Crystal Ratchapruek
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:sawitree.k@kegroup.co.th">sawitree.k@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:095-159-5709">095-159-5709</a>
      </IonCol>
    </IonRow>
     </div>
      <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        Plearnary Mall
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:thunthitha.c@kegroup.co.th">thunthitha.c@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:091-029-9667">091-029-9667</a>
      </IonCol>
    </IonRow>
      </div>
      <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        Amorini Mall
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:thunthitha.c@kegroup.co.th">thunthitha.c@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:091-029-9667">091-029-9667</a>
      </IonCol>
    </IonRow>
    </div>
    <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        I’m Park Mall
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:thunthitha.c@kegroup.co.th">thunthitha.c@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:091-029-9667">091-029-9667</a>
      </IonCol>
    </IonRow>
    </div>
    <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        Sammakorn Place Ramkhamhaeng
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:kitiya.p@kegroup.co.th">kitiya.p@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:091-174-5137">091-174-5137</a>
      </IonCol>
    </IonRow>
    </div>
    <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        Sammakorn Place Ratchapruek
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:sawitree.k@kegroup.co.th">sawitree.k@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:095-159-5709">095-159-5709</a>
      </IonCol>
    </IonRow>
    </div>
    <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        Sammakorn Place Rangsit
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:sawitree.k@kegroup.co.th">sawitree.k@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:095-159-5709">095-159-5709</a>
      </IonCol>
    </IonRow>
    </div>
    <div class="pt-3">
    <IonRow>
      <IonCol size="12">
        The Scene Town in Town
      </IonCol><br/>
      <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/mail-outline.svg" width="20" alt="mail" /> : <a href="mailto:thunthitha.c@kegroup.co.th">thunthitha.c@kegroup.co.th</a>
      </IonCol><br/>
       <IonCol size="auto" class="pl-5">
        <img src="/assets/icon/call-outline.svg" width="20" alt="phone" /> : <a href="tel:091-029-9667">091-029-9667</a>
      </IonCol>
    </IonRow>
    </div>
   </div>

    <!-- <iframe
      title="Feedback Form"
      class="freshwidget-embedded-form"
      id="freshwidget-embedded-form"
      src="https://kepm.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded"
      :style="`height: ${isIos() ? 'calc(100vh - 115px);' : 'calc(100vh - 65px);'}`"
      width="100%"
      scrolling="no"
      frameborder="0"
    >
    </iframe> -->
  </PageLayout>
</template>

<!--<script-->
<!--  type="text/javascript"-->
<!--  src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"-->
<!--&gt;</script>-->

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { IonIcon, isPlatform } from '@ionic/vue'
import { PageLayout, PageHeader } from '@/components/Layout/'
class Props {}

@Component({
  components: { PageLayout, PageHeader, IonIcon },
  props: []
})
export default class Maintenance extends Vue.with(Props) {
  isIos() {
    return isPlatform('ios')
  }

  checkIframeLoading() {
    const iframeEle = document.getElementById('freshwidget-embedded-form')
    iframeEle!.addEventListener('load', function() {
      iframeEle!.style.opacity = 1 as any
    })
  }
  mounted() {
    let e = null
    ;(e = document.createElement('script')).id = 'freshwidget-embedded-form'
    ;(e.async = !0),
      (e.src = 'https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js'),
      document.head.appendChild(e)
    this.checkIframeLoading()
  }
}
</script>

<style scoped lang="scss">
@import url('https://assets.freshdesk.com/widget/freshwidget.css');
@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
//#freshwidget-embedded-form {
//  opacity: 0;
//  height: 600px;
//  width: 100%;
//}
</style>
