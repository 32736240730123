
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle
} from '@ionic/vue'

class Props {}

@Component({
  components: { IonCard, IonGrid, IonRow, IonCol, IonCardTitle, IonCardSubtitle },
  props: []
})
export default class ProfileBanner extends Vue.with(Props) {
  goToProfile() {
    this.$router.push('/user')
  }
}
