<template>
  <IonModal :isOpen="true" size="large">
    <IonContent class="pa-2">
      <!--<template v-slot:header>-->
      <IonRow class="ion-justify-content-between" style="margin-bottom: 15px">
        <IonCol size="auto" class="title">
          {{ title }}
        </IonCol>
        <IonCol size="auto" class="lang-switch">
          <span
            :style="`color:${content === contentEn ? '$color-primary' : '#cdcdcd'} `"
            @click="content = contentEn"
          >
            EN
          </span>
          |
          <span
            :style="`color:${content === contentTh ? '$color-primary' : '#cdcdcd'} `"
            @click="content = contentTh"
          >
            ไทย
          </span>
        </IonCol>
        <IonCol size="12">
          <div class="title-underline"> </div>
        </IonCol>
      </IonRow>
      <!--</template>-->

      <!--<template v-slot:content>-->
      <IonRow style="margin-bottom: 10px">
        <IonCol class="content-wrapper" v-on:scroll="handleScroll($event)">
          <div :id="id" class="content" v-html="content"></div>
        </IonCol>
        <IonCol size="12">
          <div class="content-underline"></div>
        </IonCol>
      </IonRow>
      <!--</template>-->

      <!--<template v-slot:footer>-->
      <IonRow class="ion-justify-content-center" style="margin-bottom: 15px">
        <IonCol size="12" :style="{ textAlign: 'center' }">
          <IonButton @click="handleClose" class="close-button">
            {{ forceRead ? $t('button.accept') : $t('button.close') }}
          </IonButton>

          <!-- <IonButton
              :disabled="forceRead && !contentRead"
              @click="handleClose"
              class="close-button"
            >
              {{ forceRead ? `Accept` : `Close` }}
            </IonButton> -->
        </IonCol>
      </IonRow>
      <!--</template>-->
    </IonContent>
  </IonModal>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import FloatModal from '@/components/modals/FloatModal.vue'
import { IonRow, IonCol, IonButton, IonModal } from '@ionic/vue'

class Props {
  id!: string
  title!: string
  contentEn!: string
  contentTh!: string
  forceRead?: boolean
}

@Component({
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonModal,
    FloatModal
  },
  props: ['id', 'title', 'contentEn', 'contentTh', 'forceRead'],
  emits: ['close', 'accept']
})
export default class TermsModal extends Vue.with(Props) {
  contentRead = false
  content = ''

  mounted() {
    if (this.$i18n.locale === 'en') {
      this.content = this.contentEn
    } else {
      this.content = this.contentTh
    }
  }

  handleScroll(event: any) {
    const currentPosition = event.target.scrollTop + event.target.clientHeight
    const contentHeight = event.target.scrollHeight

    const isAtBottom = currentPosition >= contentHeight
    if (isAtBottom && !this.contentRead && this.forceRead) {
      this.contentRead = true
    }
  }

  handleClose() {
    if (this.forceRead) {
      this.$emit('accept')
    }
    this.$emit('close')
  }
}
</script>

<style scoped lang="scss">
.title {
  color: $color-primary;
  font-size: 18px;
}
.title-underline {
  background: $color-primary 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 49px;
  height: 4px;
}
.lang-switch {
  color: $color-primary;
  font-size: 14px;
}
.content-wrapper {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  margin: 2px 2px 2px 2px;
  padding: 2px 2px 2px 2px;
}
.content {
  min-height: 40vh;
  overflow-y: scroll;
}
.content-underline {
  background: #c6c6c6 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 1px;
  margin-top: 15px;
}
.close-button {
  width: 95px;
  height: 30px;
  text-transform: uppercase;
  height: 100%;
  --background: #8bdcd6;
}
</style>
