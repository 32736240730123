
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import {
  IonPage,
  IonContent,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonButton
} from '@ionic/vue'

class Props {}

@Component({
  components: {
    PageLayout,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonItem,
    IonButton
  },
  props: []
})
export default class LandingPage extends Vue.with(Props) {
  startedBtnClicked() {
    try {
      console.log('STARTD: get start')
      this.$router.push('/login')
    } catch (e) {
      console.log('STARTD: get start error', e)
    }
  }
}
