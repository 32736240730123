
import { Options as Component, Vue } from 'vue-class-component'
import { IonCard, IonCardContent, IonCol, IonRow, IonButton, IonGrid } from '@ionic/vue'
import SmallRedDot from '@/components/badge/SmallRedDot.vue'
import { Card } from '@/components/Card'
import BillState from '@/modules/bills/BillState.vue'
import CustomerDetails from '@/modules/bills/CustomerDetails.vue'
import _ from 'lodash'

class Props {
  invoiceGroup!: any
  cardStyle?: string
  highlighted?: boolean
  minimized?: boolean
}

@Component({
  components: {
    IonCard,
    IonCardContent,
    IonCol,
    IonRow,
    SmallRedDot,
    BillState,
    IonButton,
    IonGrid,
    Card,
    CustomerDetails
  },
  props: ['invoiceGroup', 'cardStyle', 'highlighted', 'minimized'],
  emits: ['ungroup']
})
export default class BillGroupCard extends Vue.with(Props) {}
