import { createStore } from 'vuex'
import OSS from 'ali-oss'
export interface StateInterface {
  user: {
    id: number
    firstName: string
    lastName: string
    newsViewCount: number
    postViewCount: number
    profileImage: string
    freshChatRestoreID: string
    email: string
    phoneNumber: string
  }
  token: string
  currentCompany: {
    id: number
  }
  currentProject: {
    id: number
    name: string
    type: string
    banner_url: string
    freshChatIntegration: {
      _id: string | null
      isEnabled: boolean
      freshChatToken: string
      userTokens: any[]
      companyId: string | null
    }
  }
  permissions: any[]
  navigationPermissions: string[]
  projects: any[]

  ossSTSToken: {
    AccessKeyId: string
    AccessKeySecret: string
    SecurityToken: string
    Expiration: string
  }
  ossClient: any

  loading: boolean[]
  loadingActive: boolean
  dialog: {
    isActive: boolean
    imageLink: string
    titleTh: string
    titleEn: string
  }
  modal: {
    policy: {
      isOpen: boolean
      forceRead: boolean
      contentTh: string
      contentEn: string
    }
    terms: {
      isOpen: boolean
      forceRead: boolean
      contentTh: string
      contentEn: string
    }
    alert: {
      isOpen: boolean
      content: string
    }
  }
  firebase: {
    token: string
    expiration: string
  }
}

const store = createStore<StateInterface>({
  state() {
    return {
      user: {
        id: -1,
        firstName: '',
        lastName: '',
        newsViewCount: 0,
        postViewCount: 0,
        profileImage: '/assets/default_profile_picture.svg',
        freshChatRestoreID: '',
        email: '',
        phoneNumber: ''
      },
      token: '',
      currentCompany: {
        id: -1
      },
      currentProject: {
        id: -1,
        name: '',
        type: '',
        banner_url: '',
        freshChatIntegration: {
          _id: null,
          isEnabled: false,
          freshChatToken: '',
          userTokens: [],
          companyId: null
        }
      },
      permissions: [] as any[],
      navigationPermissions: [] as string[],
      projects: [] as any[],

      loading: [] as boolean[],
      loadingActive: false as boolean,
      dialog: {
        isActive: false as boolean,
        imageLink: '',
        titleTh: 'ปิดปรับปรุงระบบชั่วคราว เราจะกลับมาให้บริการเร็ว ๆ นี้',
        titleEn: 'The system is unavailable, we will be back soon.'
      },
      modal: {
        policy: {
          isOpen: false,
          forceRead: false,
          contentTh: '',
          contentEn: ''
        },
        terms: {
          isOpen: false,
          forceRead: false,
          contentTh: '',
          contentEn: ''
        },
        alert: {
          isOpen: false,
          content: ''
        }
      },
      ossSTSToken: {
        AccessKeyId: '',
        AccessKeySecret: '',
        SecurityToken: '',
        Expiration: ''
      },
      ossClient: {} as any,
      firebase: {
        token: '',
        expiration: ''
      }
    }
  }
})

export default store
