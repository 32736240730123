<template>
  <PageLayout>
    <PageHeader :title="$t('service.title')" :withBackButton="true">
      <template v-slot:action>
        <IonButton mode="md" fill="clear" :style="{ float: 'right' }">
          <IonIcon color="primary" icon="duplicate" />
        </IonButton>
      </template>
    </PageHeader>

    <IonCard
      @click="serviceClicked(service.name, service.iframeLink)"
      v-for="(service, i) in serviceList"
      :key="i + service.name"
    >
      <IonCardHeader>
        <img :src="service.src" alt="seekter" />
        <!--        <IonCardSubtitle>{{ service.name }}</IonCardSubtitle>-->
        <IonCardTitle>{{ service.title }}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <p>
          {{ service.content }}
        </p>
      </IonCardContent>
    </IonCard>
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import PageLayout from '@/components/Layout/PageLayout.vue'
import { PageHeader } from '@/components/Layout'
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonIcon
} from '@ionic/vue'

class Props {}

@Component({
  components: {
    PageLayout,
    PageHeader,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonIcon
  },
  props: []
})
export default class Services extends Vue.with(Props) {
  serviceList = [
    {
      src: '/assets/img/banner-seekster.png',
      name: 'Seekster',
      title: 'Seekster',
      content:
        'บริการทำความสะอาดสำหรับร้านค้า การันตีคุณภาพ ได้มาตรฐาน เรียกใช้ง่าย รับประกันงานและความเสียหาย',
      iframeLink: 'https://get.seekster.co/kegroup/'
    },
    {
      src: '/assets/img/services/FoodStory.png',
      name: 'Foodstory',
      title: 'Foodstory',
      content:
        'แหล่งรวมวัตถุดิบออนไลน์ ที่จะทำให้ร้านอาหารหรือคาเฟ่ของคุณประหยัดได้มากกว่าเดิม ทั้งประหยัดเวลา ประหยัดที่จัดเก็บ และประหยัดเงิน ส่งวัตถุดิบสดใหม่ได้คุณภาพถึงร้านค้าของคุณ',
      iframeLink: 'https://skycommercial.skyos.tech/landingfoodstory'
    },
    {
      src: '/assets/img/services/Marketing.png',
      name: 'Marketing',
      title: 'Marketing',
      content:
        'เคอี กรุ๊ป ร่วมกับ Fastwork แพลตฟอร์มรวมกลุ่มมืออาชีพด้านการตลาด เพื่อนำเสนอบริการด้านการตลาดหลากหลายประเภท เพื่อตอบโจทย์การทำการตลาดของร้านค้าได้มากกว่าเดิม',
      iframeLink: 'https://skycommercial.skyos.tech/landingmarket'
    }
    // {
    //   src: '/assets/img/services/Finance.png',
    //   name: 'Financial',
    //   title: 'Financial',
    //   content:
    //     'บริการที่ปรึกษาทางด้านการเงิน การเข้าถึงแหล่งเงินทุน โดยเคอี กรุ๊ป จะเป็นตัวกลางการติดต่อกับสถาบันทางการเงินชั้นนำของประเทศที่เป็นพันธมิตร ให้ร้านค้าเข้าถึงแหล่งเงินทุนมากขึ้น',
    //   iframeLink: ''
    // }
  ]

  serviceClicked(name: string, iframeLink: string) {
    this.$router.push({ path: '/services/' + name, query: { iframeLink } })
  }
}
</script>

<style scoped lang="scss"></style>
