require('dotenv').config()
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import '@/theme/variables.scss'

import * as auth from 'firebase/auth'
import * as firestore from 'firebase/firestore'
//store & plugin
import store from './store/index'
import loading from '@/plugins/loading'
import ossClient from '@/plugins/ossClient'
/*external lib*/
// import { createI18n } from 'vue-i18n'
import i18n from './i18n'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import axios from './http'
import VueAxios from 'vue-axios'
import dayjs from '@/utils/dayjs'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
firebase.initializeApp(firebaseConfig)
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(loading)
  .use(ossClient)
  .use(VueTelInput)
  .use(i18n)
  .mixin({
    methods: {
      THAI_DATE: (date: any) => {
        if (date)
          return dayjs(date)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY')
        else return ''
      },
      THAI_TIME: (time: any) => {
        if (time) {
          return dayjs(time)
            .tz('Asia/Bangkok')
            .format('HH:mm')
        } else {
          return ''
        }
      },
      DATE_TIME_RELATIVE: function(timeStamp: any) {
        const date: any = new Date(timeStamp)
        const now = new Date()
        const secondsPast = (now.getTime() - date) / 1000
        if (secondsPast < 3600) {
          return (secondsPast / 60).toFixed(0) + ' ' + this.$t('time_format.minutes_ago')
        }
        if (secondsPast <= 86400) {
          return (secondsPast / 3600).toFixed(0) + ' ' + this.$t('time_format.hours_ago')
        }
        if (secondsPast > 86400) {
          return dayjs(date)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm')
        }
      },
      GET_SECURED_IMAGE(path: string) {
        return store.state.ossClient.signatureUrl(path)
      },
      REMOVE_HTML_TAG(text: string) {
        return text.replace(/<[^>]*>/g, '')
      },
      I18N(textTh: string, textEng: string) {
        return i18n.global.locale === 'th' ? textTh : textEng
      }
    }
  })
router.isReady().then(() => {
  app.mount('#app')
})
