<template>
  <div
    :style="disabled ? 'opacity: 0.5' : null"
    @click="disabled ? null : homeMenuClicked()"
    :class="`ripple-parent menu-container ${disabled ? 'overlay' : 'ion-activatable'} `"
  >
    <RedDot
      v-if="!disabled"
      :hidden="redDot.hidden || redDot.count <= 0"
      :count="redDot.count"
    />
    <div class="container"></div>
    <img :src="src" alt="home icon" />
    <div class="menu-title">{{ name }}</div>
    <IonRippleEffect />
  </div>
</template>
<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import RedDot from '@/components/badge/RedDot.vue'
import { IonRippleEffect } from '@ionic/vue'

class Props {
  'name'!: string
  'src'!: string
  'redDot'!: {
    count?: number | 0
    hidden?: boolean | true
  }
  'routerLink'!: string
  'disabled'!: boolean | false
}

@Component({
  components: { RedDot, IonRippleEffect },
  props: ['name', 'src', 'redDot', 'routerLink', 'disabled']
})
export default class HomeMenu extends Vue.with(Props) {
  homeMenuClicked() {
    this.$router.push(this.routerLink)
  }
  getCount = () => this.redDot.count
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row-reverse;
}
.menu-container {
  background-color: white;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 10px;
  margin-left: 4px;
  margin-right: 4px;
  height: 90px;
  width: 110px;

  .menu-title {
    text-align: center;
    font: normal normal normal 14px Prompt;
    letter-spacing: 1px;
    color: #333333;
    text-transform: capitalize;
    opacity: 1;
    padding: 5px 0px 5px 0px;
    //text-align: center;
  }

  img {
    width: 40px;
    height: 40px;
  }
}
.overlay {
  opacity: 0.3;
}
//.menu-card {
//  width: 70px;
//  height: 90px;
//}
</style>
